import React from 'react'
import Layout from '@components/Layout'
import { Config, Routes } from '@constants'
import HelmetHelper from '@components/HelmetHelper'
import FeatureListItem from '@components/features/FeatureListItem'
import FeatureHeader from '@components/features/FeatureHeader'
import { getDayName } from '@utils'
import BottomCta from '@components/BottomCta'
import FeatureRedirectList from '@components/features/FeatureRedirectList'
import PopularQuestionList, {
  createQuestionItem,
} from '@components/custom/PopularQuestionList'
import Typography from '@atoms/Typography'
import Testimonials from '@components/Testimonials'
import videoPreview from '@static/features/preview.mp4'
import videoDynamic from '@static/features/dynamic.mp4'
import videoWeekday from '@static/features/weekday.mp4'
import imgPersonalize from '@static/features/personalize-1.svg'
import imgPersonalMain from '@static/features/personalized-main.svg'

const dayName = getDayName()

const POPULAR_QUESTION_ITEMS = [
  createQuestionItem(
    'How do I personalize LinkedIn outreach at scale?',
    `
  If you need an easy way to scale your personalization efforts on LinkedIn without losing that personal touch, you should try ${Config.NAME}. A LinkedIn automation tool that helps you scale your outreach activities beyond just a "Firstname" personalization.`
  ),
  createQuestionItem(
    'How do I write personalized connection requests?',
    <>
      On LinkedIn, a well-crafted personalized message can go a long way.
      However, you might find it hard to know what to write in the message box.
      We&apos;ve created{' '}
      <a
        target="_blank"
        href={Routes.BLOG.TEMPLATES_ARTICLE}
        className="text-link hover:underline"
        rel="noreferrer"
      >
        LinkedIn outreach templates
      </a>
      , so you&apos;ll always have the right words no matter with whom
      you&apos;re connecting.
    </>
  ),
  createQuestionItem(
    'Is LinkedIn personalized outreach more effective?',
    `Sending outreach messages to your audience through LinkedIn is highly competitive. The right strategy will increase your chances of success. Personalizing your messages is essential to be viewed as a real person rather than an automated one. Remember that most people expect to receive automated messages, so surprise them by personalizing your copy.`
  ),
  createQuestionItem(
    'What is an outreach message on LinkedIn?',
    `It's the message or sequence of messages you send to your contacts on LinkedIn to get in touch with them. People are busy and don't always have time to read lengthy messages. You should keep your text short and direct. It's also a good idea to personalize it as much as possible. This will increase the chances of your message being viewed and responded to.`
  ),
  createQuestionItem(
    'What is the best tool for personalized LinkedIn outreach?',
    `If you need an easy-to-use yet powerful LinkedIn automation tool to personalize your outreach at scale, ${Config.NAME} is for you. ${Config.NAME} makes it easy to personalize invitations, messages, and InMails with contact information, including name, company, position, and more.`
  ),
  createQuestionItem(
    `Can I preview outreach messages before they are sent?`,
    `Yes, you can preview your personalized messages for each prospect before sending out your campaign. Simply go to your campaign and click on the Preview button inside the sequence view.`
  ),
]

const PersonalizePage = () => (
  <Layout>
    <HelmetHelper
      title={`Smart LinkedIn Outreach Personalization - ${Config.NAME}`}
      description={`${Config.NAME} allows you to personalize your LinkedIn outreach depending on who your prospects are, what they do, and how they act inside your sales funnel.`}
    />
    <FeatureHeader
      title="Smart LinkedIn Outreach Personalization"
      subtitle="Tailor your outreach depending on who your prospects are, what they do, and how they act inside your sales funnel."
      image={imgPersonalMain}
      clientText="LinkedIn outreach personalization tool"
    />

    <div className="pt-20">
      <div className="mx-auto w-full text-center max-w-full md:max-w-screen-lg">
        <Typography.H2>
          Go beyond "Firstname" personalization. <br />
          Customize your LinkedIn outreach the smart way.
        </Typography.H2>
        <Typography.SUBTEXT>
          Add specific details to each message to make it unique and stand out.
          Personalize your messages with smart placeholders and dynamic IF/ELSE
          options.
        </Typography.SUBTEXT>
      </div>

      <FeatureListItem
        title="From now on, your messages will stand out in a crowd"
        subtitle={
          <>
            <div className="mb-2">
              Send personalized invitations, open InMails and, messages. Add
              profile visits to maximize getting in touch with your leads.
            </div>
            <div>
              Write your own icebreaker lines, genuine compliments, and catchy
              call-to-actions.
            </div>
          </>
        }
        image={imgPersonalize}
        button="Get started"
        link={Routes.APP.USER.SIGN_UP}
      />
      <FeatureListItem
        title="Preview your message before your campaign starts"
        subtitle={
          <>
            <div className="mb-2">
              Preview mode gives you an idea of how each step of your sequence
              will seem from the perspective of your prospects.
            </div>
            <div>
              Make sure your message templates are working and all data is
              available before you start your outreach.
            </div>
          </>
        }
        reverseOrder
        video={videoPreview}
        button="Get started"
        link={Routes.APP.USER.SIGN_UP}
      />
      <FeatureListItem
        title={
          <>
            Did you say smart placeholders? Happy{' '}
            <span className="text-primary-main">{dayName}!</span>
          </>
        }
        subtitle="Go the extra mile with your personalization efforts. Use clever placeholders like today's weekday to make your messages more diverse and a lot more human."
        video={videoWeekday}
        button="Get started"
        link={Routes.APP.USER.SIGN_UP}
      />

      <FeatureListItem
        title="Build IF/ELSE template scenarios"
        subtitle="Create custom messages for each prospect with dynamic placeholders. Don't just change the name or a few lines. Use dynamic formulas to create the entire template automatically."
        video={videoDynamic}
        button="Get started"
        reverseOrder
        link={Routes.APP.USER.SIGN_UP}
      />
    </div>
    <Testimonials title="LinkedIn outreach tool that people trust" />
    <BottomCta
      title="Ready to start 7-days for free?"
      subtitle="Send your first personalized LinkedIn outreach sequence today."
      button="Try it free"
    />
    <PopularQuestionList list={POPULAR_QUESTION_ITEMS} />
    <FeatureRedirectList />
  </Layout>
)

export default PersonalizePage
